import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import "./App.css";
import WordBee from "./WordBee";
import Home from "./Home";

function App() {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/4" element={<WordBee letter={4} />} />
				<Route path="/5" element={<WordBee letter={5} />} />
				<Route path="*" element={<h1>404 Page Not Found</h1>} />
			</Routes>
		</Router>
	);
}

export default App;
