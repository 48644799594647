import React from "react";

export default function Details() {
	return (
		<>
			<div>
				<img
					src={process.env.PUBLIC_URL + "/img/WORD-BEE.jpg"}
					style={{ margin: "0% 40%", width: "20%" }}
				/>
				<h1>What is Word-Bee?</h1>
				Word-Bee is a very simple game to play : easy to understand and
				fun to play.
				<br />
				<br />
				You can only play one puzzle a day.
				<br />
				Everyone plays the same puzzle.
				<br />
				It is easy to share your game on social media without spoiling
				the answer for others.
				<br />
			</div>
			<div>
				<h1>Aim</h1>
				Your challenge is to guess a four-letter or five-letter word in
				six attempts. Each time you guess, you're told which of your
				chosen letters are in the target word, and whether they are in
				the right place. And that's it.
				<h1>Rules</h1>
				<ol
					style={{
						marginLeft: "20px",
					}}
				>
					<li>
						You have to guess the correct word in six attempts or
						less.
					</li>
					<li>If a letter is in the correct place, it turns blue.</li>
					<li>
						If a letter is in the word but in the wrong place, it
						turns yellow.
					</li>
					<li>If a letter is not in the word, it turns gray.</li>
					<li>Letters can be used more than once.</li>
					<li>Answers are never plurals.</li>
					<li>Press Enter to submit a word for checking.</li>
					<li>
						Press Delete to remove a letter which you have entered.
					</li>
					<li>
						You can share your Word-Bee score after you have
						completed it.
					</li>
				</ol>
			</div>
		</>
	);
}
