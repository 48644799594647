import React from "react";
import Details from "./Details";

export default function Home() {
	return (
		<div className="container">
			<div className="details" style={{ textAlign: "unset" }}>
				<Details />
				<br />
				<div
					style={{
						textAlign: "center",
					}}
				>
					<a href="4/">
						<button id="other_wordbee">4-letter Word-Bee</button>
					</a>
					<a href="5/">
						<button id="other_wordbee">5-letter Word-Bee</button>
					</a>
				</div>
			</div>
		</div>
	);
}
