import { useEffect, useState } from "react";
import { WORDS5 } from "./words5";
import { WORDS4 } from "./words4";
import Details from "./Details";

const NUMBER_OF_GUESSES = 6;

const animateCSS = (element, animation, prefix = "animate__") =>
	// We create a Promise and return it
	new Promise((resolve, reject) => {
		const animationName = `${prefix}${animation}`;
		// const node = document.querySelector(element);
		const node = element;
		node.style.setProperty("--animate-duration", "0.3s");

		node.classList.add(`${prefix}animated`, animationName);

		// When the animation ends, we clean the classes and resolve the Promise
		function handleAnimationEnd(event) {
			event.stopPropagation();
			node.classList.remove(`${prefix}animated`, animationName);
			resolve("Animation ended");
		}

		node.addEventListener("animationend", handleAnimationEnd, {
			once: true,
		});
	});

function shadeKeyBoard(letter, color) {
	for (const elem of document.getElementsByClassName("keyboard-button")) {
		if (elem.textContent === letter) {
			let oldColor = elem.style.backgroundColor;

			if (oldColor === "rgb(33, 194, 248)") {
				//check blue
				return;
			}

			if (oldColor === "yellow" && color !== "#21C2F8") {
				return;
			}

			elem.style.backgroundColor = color;
			animateCSS(elem, "pulse");
			break;
		}
	}
}

function copy() {
	$("#msg_to_copy").select();
	document.execCommand("Copy");
	window.getSelection().removeAllRanges();
	toastr.info("Copied to Clipboad!");
}

export default function WordBee(props) {
	const { letter: NUMBER_OF_LETTERS } = props;
	const WORDS = NUMBER_OF_LETTERS === 4 ? WORDS4 : WORDS5;
	const storageKey = `wordbee${NUMBER_OF_LETTERS}`;

	toastr.options = {
		closeButton: true,
		newestOnTop: false,
		progressBar: false,
		positionClass: "toast-top-center",
		preventDuplicates: false,
		onclick: null,
		showDuration: "300",
		hideDuration: "1000",
		timeOut: "3000",
		extendedTimeOut: "1000",
		showEasing: "swing",
		hideEasing: "linear",
		showMethod: "fadeIn",
		hideMethod: "fadeOut",
	};

	const [gameState, setGameState] = useState(1);
	const [data, setData] = useState(null);
	const [guesses, setGuesses] = useState([]);
	const [wordbee, setWordbee] = useState(null);
	const [msg, setMsg] = useState("");

	useEffect(() => {
		$.ajax({
			url: "https://indiaspellingbee.com/games/Word-Bee/api.php",
			type: "post",
			data: {
				letters: NUMBER_OF_LETTERS,
				wordbee: localStorage.getItem(storageKey)
					? JSON.parse(localStorage.getItem(storageKey))
					: null,
			},
			success: function (response) {
				let wordbee = response.wordbee;
				localStorage.setItem(storageKey, JSON.stringify(wordbee));
				setWordbee(wordbee);

				setData({
					...response.current_word,
					prevWord: response.prevWord,
				});

				setGameState(response.state);

				var guesses = wordbee.guessList.split(",");
				if (guesses[0] === "") guesses.shift();
				setGuesses([...guesses, ""]);

				for (let i = 0; i < guesses.length; i++) {
					if (guesses[i] === "") continue; // skip first empty string
					var delay = 100 * i + 100;
					setTimeout(
						() =>
							gradeWord(
								guesses[i],
								i,
								Array.from(response.current_word.word)
							),
						delay
					);
				}

				if (response.state === 3) {
					setTimeout(
						() =>
							response.current_word.word ===
							guesses[guesses.length - 1]
								? onWin()
								: onLoose(response.current_word.word),
						1000
					);
				}
			},
			error: function (xhr, ajaxOptions, thrownError) {
				// alert(xhr.status);
				alert(thrownError);
			},
		});
	}, []);

	const addLetter = (letter) => {
		if (gameState === 3) return;
		let lastGuess = guesses[guesses.length - 1];
		if (lastGuess.length < NUMBER_OF_LETTERS) {
			lastGuess += letter;
			setGuesses([...guesses.slice(0, guesses.length - 1), lastGuess]);
			animateCSS(
				document
					.getElementById("game-board")
					.getElementsByClassName("letter-box")[
					(guesses.length - 1) * NUMBER_OF_LETTERS +
						lastGuess.length -
						1
				],
				"pulse"
			);
		}
	};

	const deleteLetter = () => {
		if (gameState === 3) return;
		let lastGuess = guesses[guesses.length - 1];
		if (lastGuess.length > 0) {
			lastGuess = lastGuess.slice(0, -1);
			setGuesses([...guesses.slice(0, guesses.length - 1), lastGuess]);
		}
	};

	const gradeWord = (guess, row, correct) => {
		var letterColor = ["gray", "gray", "gray", "gray", "gray"];
		guess = Array.from(guess);

		//check blue
		for (let i = 0; i < NUMBER_OF_LETTERS; i++) {
			if (correct[i] == guess[i]) {
				letterColor[i] = "#21C2F8"; //blue
				correct[i] = "#";
			}
		}

		//check yellow
		//checking guess letters
		for (let i = 0; i < NUMBER_OF_LETTERS; i++) {
			if (letterColor[i] == "#21C2F8") continue;

			//checking right letters
			for (let j = 0; j < NUMBER_OF_LETTERS; j++) {
				if (correct[j] == guess[i]) {
					letterColor[i] = "yellow";
					correct[j] = "#";
				}
			}
		}

		var msg = "";
		for (let i = 0; i < NUMBER_OF_LETTERS; i++) {
			let box = document
				.getElementById("game-board")
				.getElementsByClassName("letter-box")[
				row * NUMBER_OF_LETTERS + i
			];
			let delay = 250 * i * (row == guesses.length - 1 ? 1 : 0.4);

			setTimeout(() => {
				//flip box
				animateCSS(box, "flipInX");
				//shade box
				box.style.backgroundColor = letterColor[i];
				shadeKeyBoard(guess[i], letterColor[i]);
				//last letter
			}, delay);

			if (letterColor[i] == "#21C2F8") msg = msg + "🟦";
			else if (letterColor[i] == "yellow") msg = msg + "🟨";
			else msg = msg + "⬛";
			if (i == NUMBER_OF_LETTERS - 1) msg = msg + "\n";
		}
		setMsg((prev) => {
			return prev.split("\n").length == row + 1 ? prev + msg : prev;
		});
	};

	const onWin = () => {
		toastr.success("You have guessed correctly!");
		setGameState(3);

		var date = new Date();
		date =
			"" +
			date.getFullYear() +
			"-" +
			(date.getMonth() + 1) +
			"-" +
			date.getDate();

		setMsg((prev) => {
			if (prev.startsWith("My")) return prev;

			var text =
				"My " +
				NUMBER_OF_LETTERS +
				"-letter Word-Bee score for today (" +
				date +
				") is : " +
				guesses.length +
				"/6\n\n" +
				prev +
				"\n" +
				location.origin +
				"/" +
				NUMBER_OF_LETTERS +
				"/";

			var wa_msg = text
				.replace(/🟦/g, "%F0%9F%9F%A6")
				.replace(/🟨/g, "%F0%9F%9F%A8")
				.replace(/⬛/g, "%E2%AC%9B")
				.replace(/\n/g, "%0A");

			$("#WhatsApp-share").attr(
				"href",
				"https://api.whatsapp.com/send?text=" + wa_msg
			);

			return text;
		});

		$("#sharebox").show();
		setTimeout(() => {
			$("#msg_to_copy").height($("#msg_to_copy").prop("scrollHeight"));
		}, 0);
	};

	const onLoose = (word) => {
		toastr.error("You lost! The word was " + word.toUpperCase());
		setGameState(3);

		var date = new Date();
		date =
			"" +
			date.getFullYear() +
			"-" +
			(date.getMonth() + 1) +
			"-" +
			date.getDate();

		setMsg((prev) => {
			if (prev.startsWith("My")) return prev;

			var text =
				"My " +
				NUMBER_OF_LETTERS +
				"-letter Word-Bee score for today (" +
				date +
				") is : X/6\n\n" +
				prev +
				"\n" +
				location.origin +
				"/" +
				NUMBER_OF_LETTERS +
				"/";

			var wa_msg = text
				.replace(/🟦/g, "%F0%9F%9F%A6")
				.replace(/🟨/g, "%F0%9F%9F%A8")
				.replace(/⬛/g, "%E2%AC%9B")
				.replace(/\n/g, "%0A");

			$("#WhatsApp-share").attr(
				"href",
				"https://api.whatsapp.com/send?text=" + wa_msg
			);

			return text;
		});

		$("#sharebox").show();
		setTimeout(() => {
			$("#msg_to_copy").height($("#msg_to_copy").prop("scrollHeight"));
		}, 0);
	};

	const submitWord = (win) => {
		$.ajax({
			url: "https://indiaspellingbee.com/games/Word-Bee/submit.php",
			type: "post",
			data: {
				letters: NUMBER_OF_LETTERS,
				word: guesses[guesses.length - 1],
				success: win ? 1 : 0,
				chances: guesses.length,
				wordbee: JSON.parse(localStorage.getItem(storageKey)),
			},
			success: function (response) {
				localStorage.setItem(
					storageKey,
					JSON.stringify(response.wordbee)
				);
				setWordbee(response.wordbee);
			},
			error: function (xhr, ajaxOptions, thrownError) {
				// alert(xhr.status);
				alert(thrownError);
			},
		});
	};

	const checkWord = () => {
		const guess = guesses[guesses.length - 1];
		let correct = data.word;

		if (guess.length !== NUMBER_OF_LETTERS) {
			toastr.error("Insufficient letters");
			return;
		}

		if (!WORDS.includes(guess)) {
			toastr.error("Word not in list!");
			return;
		}

		gradeWord(guess, guesses.length - 1, Array.from(correct));

		let wordbee = JSON.parse(localStorage.getItem(storageKey));
		wordbee.guessList += "," + guess;
		localStorage.setItem(storageKey, JSON.stringify(wordbee));
		setWordbee(wordbee);

		if (guess === correct) {
			submitWord(true);
			onWin();
		} else if (guesses.length === NUMBER_OF_GUESSES) {
			submitWord(false);
			onLoose(correct);
		} else {
			setGuesses([...guesses, ""]);
		}
	};

	const type = (event) => {
		if (event.key === "Enter") {
			checkWord();
		} else if (event.key === "Backspace" || event.key === "Delete") {
			deleteLetter();
		} else if (event.key >= "a" && event.key <= "z") {
			addLetter(event.key);
		}
		// else if (event.key >= "A" && event.key <= "Z") {
		// 	addLetter(event.key.toLowerCase());
		// }
	};
	useEffect(() => {
		document.addEventListener("keydown", type);
		return () => {
			document.removeEventListener("keydown", type);
		};
	}, [type]);

	return (
		<div className="container">
			<div className="details" style={{ textAlign: "unset" }}>
				<Details />
			</div>
			<div className="details">
				<div>
					<h3>PREVIOUS DAY'S WORD WAS :</h3>
					<div style={{ display: "flex", justifyContent: "center" }}>
						{Array.from({
							length: NUMBER_OF_LETTERS,
						}).map((_, i) => (
							<div
								key={i}
								className="letter-box"
								style={{ backgroundColor: "rgb(33, 194, 248)" }}
							>
								{data && data.prevWord.charAt(i)}
							</div>
						))}
					</div>
					<br />
				</div>
				<>
					{gameState == 1 && "Welcome to today's puzzle!"}
					{gameState == 2 && "Continue solving today's puzzle!"}
					{gameState == 3 &&
						"You have already played this puzzle today!! Come back again tomorrow!"}
				</>
				<div id="game-board">
					{Array.from({ length: NUMBER_OF_GUESSES }).map((_, i) => (
						<div key={i} className="letter-row">
							{Array.from({
								length: NUMBER_OF_LETTERS,
							}).map((_, j) => (
								<div key={j} className="letter-box">
									{data && guesses[i]
										? guesses[i].length > j
											? guesses[i].charAt(j)
											: ""
										: ""}
								</div>
							))}
						</div>
					))}
				</div>
				<div id="keyboard-cont">
					<div className="first-row">
						{["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"].map(
							(letter) => (
								<button
									key={letter}
									onClick={() => addLetter(letter)}
									className="keyboard-button"
								>
									{letter}
								</button>
							)
						)}
					</div>
					<div className="second-row">
						{["a", "s", "d", "f", "g", "h", "j", "k", "l"].map(
							(letter) => (
								<button
									key={letter}
									onClick={() => addLetter(letter)}
									className="keyboard-button"
								>
									{letter}
								</button>
							)
						)}
					</div>
					<div className="third-row">
						<button
							className="keyboard-button"
							onClick={() => deleteLetter()}
						>
							Del
						</button>
						{["z", "x", "c", "v", "b", "n", "m"].map((letter) => (
							<button
								key={letter}
								onClick={() => addLetter(letter)}
								className="keyboard-button"
							>
								{letter}
							</button>
						))}
						<button
							className="keyboard-button"
							onClick={() => checkWord()}
						>
							Enter
						</button>
					</div>
				</div>
			</div>
			<div className="details">
				<h2>Your Stats:</h2>
				Games played: {wordbee?.attempts}
				<br /> Games won: {wordbee?.success}
				<br /> Win rate:{" "}
				{Math.round(
					((wordbee?.success * 100.0) / wordbee?.attempts +
						Number.EPSILON) *
						100
				) / 100}
				% <br />
				{wordbee?.success != 0 && (
					<>
						<br />
						Average chances taken:{" "}
						{Math.round(
							((wordbee?.chances * 1.0) / wordbee?.success +
								Number.EPSILON) *
								100
						) / 100}
					</>
				)}
				<br />
				Current Win Streak: {wordbee?.streak}
				<br /> Last played on: {wordbee?.last_attempt}
				<br />
				<br />
				<h2>Today's Stats:</h2>
				Average success rate :{" "}
				{Math.round(
					((data?.success * 100.0) / data?.frequency +
						Number.EPSILON) *
						100
				) / 100}
				% <br />
				{data?.success != 0 &&
					"Average Chances : " +
						Math.round(
							((data?.chances * 1.0) / data?.success +
								Number.EPSILON) *
								100
						) /
							100}
				<br />
				<br />
				<a href={"/" + (NUMBER_OF_LETTERS == 4 ? "5" : "4")}>
					<button id="other_wordbee">
						Play the {NUMBER_OF_LETTERS == 4 ? "5" : "4"}
						-letter Word-Bee here
					</button>
				</a>
				<br />
				<div
					id="sharebox"
					style={{ display: "none", textAlign: "center" }}
				>
					<h2>Share your score:</h2>
					<textarea
						id="msg_to_copy"
						style={{
							width: "90%",
							padding: "5px",
							border: "1px solid #ccc",
							borderRadius: "4px",
							resize: "none",
						}}
						readOnly
						value={msg}
					></textarea>
					<br />
					<a hreaf="#" target="_blank" id="WhatsApp-share">
						<button className="share-button">
							Share on WhatsApp
						</button>
					</a>
					&nbsp;&nbsp;
					<a onClick={copy}>
						<button className="share-button">Copy</button>
					</a>
				</div>
			</div>
		</div>
	);
}
